import React from "react";
import TextBlock from "./TextBlock";
import Menu from "./Menu";
import * as styles from "./TechExpertisePage.module.scss";
import { graphql, withPrefix } from "gatsby";
import useSiteMetadata from "../../components/Layout/SiteMetadata";
import { SiteMetadataHead } from "../../components/Shared/SiteMetadataHead";

interface IExpertiseProps {
    data: GatsbyTypes.TechExpertisesQuery;
    location: Location;
}
const TechExpertisePageTemplate = (props: IExpertiseProps) => {
    const { strapiTechExpertisesPage, allStrapiTechExpertisesPage } = props.data;

    return (
        <div className={`${styles.contentWrapper} mainBox px-3`}>
            <Menu expertiseList={allStrapiTechExpertisesPage.nodes} location={props.location} />
            {strapiTechExpertisesPage && <TextBlock {...strapiTechExpertisesPage.expertise} />}
        </div>
    );
};

export default TechExpertisePageTemplate;

export const query = graphql`
    query TechExpertises($id: String!) {
        strapiTechExpertisesPage(id: { eq: $id }) {
            id
            title
            seo {
                description
                twitter {
                    card
                    description
                    title
                    image {
                        url
                    }
                    site
                }
                facebook {
                    image {
                        url
                    }
                    description
                    title
                    url
                    type
                }
            }
            expertise {
                heading
                paragraphHTML
            }
        }
        allStrapiTechExpertisesPage {
            nodes {
                id
                nav
                title
                expertise {
                    imageSVG
                }
            }
        }
    }
`;

export function Head({ pageContext }: any) {
    const { seo } = pageContext;

    return (
        <SiteMetadataHead>
            <title id='site-title'>{seo.title}</title>
            <meta id='desc' name="description" content={seo.description} />
            <meta id='tw-card' name="twitter:card" content={seo.twitter.card } />
            <meta id='tw-site' name="twitter:site" content={seo.twitter.site} />
            <meta id='tw-creator' name="twitter:creator" content={seo.twitter.creator } />
            <meta id='tw-title' name="twitter:title" content={seo.twitter.title } />
            <meta id='tw-desc' name="twitter:description" content={seo.twitter.description } />
            <meta id='tw-img' name="twitter:image" content={seo.twitter.image.url } />
            <meta id='fb-type' property="og:type" content={seo.facebook.type } />
            <meta id='fb-title' property="og:title" content={seo.facebook.title } />
            <meta id='fb-desc' property="og:description" content={seo.facebook.description } />
            <meta id='fb-url' property="og:url" content={seo.facebook.url } />
            <meta id='fb-img' property="og:image" content={seo.facebook.image.url } />
        </SiteMetadataHead>
    );
}