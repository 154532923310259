import React from "react";
import * as styles from "./TextBlock.module.scss";

const TextBlock = ({ heading, paragraphHTML }: any) => {
    return (
        <section className={styles.wrapper}>
            <h1 className={styles.expertiseTitle}>{heading}</h1>
            <div className={styles.expertiseParagraph} dangerouslySetInnerHTML={{ __html: paragraphHTML }} />
        </section>
    );
};

export default TextBlock;
