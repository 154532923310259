import React, { useState, useEffect, useRef } from "react";

import useWindowWidth from "../../../hooks/useWindowWidth";
import * as styles from "./Menu.module.scss";
import slugify from "../../../helpers";
import { Link } from "gatsby";

let isMenuFirstRender = true;

const Menu = ({ expertiseList, location }: any) => {
    const [showList, setShowList] = useState(false);
    const navWrapper = useRef<HTMLElement>(null);
    const isDesktop = useWindowWidth(992);

    useEffect(() => {
        if (isMenuFirstRender && isDesktop) {
            document.documentElement.style.setProperty("--expertiseMenuHeight", `${navWrapper.current?.getBoundingClientRect().height}px`);

            isMenuFirstRender = false;
        }
    }, [isDesktop]);

    const sortFunction = (a: any, b: any, key: any) => (a[key] > b[key] ? 1 : -1 || 0);
    const isActive = (slug: any) => {
        return location && location.pathname.includes(slug);
    };

    let activeItem = expertiseList.filter((info: any) => isActive(slugify(info.title)));

    return (
        expertiseList && (
            <nav className={styles.navWrapper} ref={navWrapper}>
                {!showList && (
                    <button className={styles.mobileToggler} onClick={() => setShowList(true)}>
                        <span dangerouslySetInnerHTML={{ __html: activeItem[0].expertise.imageSVG }} />
                        <span>{activeItem[0].nav}</span>
                    </button>
                )}

                <ul className={`${styles.listWrapper} ${showList ? styles.showList : styles.hideList}`}>
                    {expertiseList
                        .sort((a: any, b: any) => sortFunction(a, b, "title"))
                        .map((info: any, i: any) => (
                            <li key={i}>
                                <Link
                                    className={styles.link}
                                    activeClassName={styles.isActive}
                                    to={`/tech-expertise/${slugify(info.title)}`}
                                    onClick={() => setShowList(false)}>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: info.expertise.imageSVG,
                                        }}
                                    />
                                    <span>{info.nav}</span>
                                </Link>
                            </li>
                        ))}
                </ul>
            </nav>
        )
    );
};

export default Menu;
